<template>
  <v-row align="center" justify="center" class="pa-4">
    <v-col>
      <div class="fn24-weight7 my-4 text-center" style="color: #1a347f">
        Privacy Policy
      </div>
    </v-col>
    <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
      <div class="fn12">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dui non mauris
        lobortis congue. Ut eu dui vitae praesent dolor nulla id. Cursus
        placerat commodo fringilla enim condimentum. Turpis aliquam lectus
        aliquet nibh amet tortor tincidunt mauris quis. Malesuada enim in
        aliquet ornare tristique. Id enim hac facilisi vitae at augue venenatis,
        eleifend. Sagittis risus nunc odio diam facilisis amet. Sed tincidunt
        fusce nullam volutpat sapien. Morbi quam nec bibendum pharetra elit
        interdum scelerisque. Sem aliquam, sapien, accumsan turpis libero
        aliquam risus. Posuere praesent sit metus nibh eget posuere sit. Lorem
        ipsum dolor sit amet, consectetur adipiscing elit. Dui non mauris
        lobortis congue. Ut eu dui vitae praesent dolor nulla id. Cursus
        placerat commodo fringilla enim condimentum. Turpis aliquam lectus
        aliquet nibh amet tortor tincidunt mauris quis. Malesuada enim in
        aliquet ornare tristique. Id enim hac facilisi vitae at augue venenatis,
        eleifend. Sagittis risus nunc odio diam facilisis amet. Sed tincidunt
        fusce nullam volutpat sapien. Morbi quam nec bibendum pharetra elit
        interdum scelerisque. Sem aliquam, sapien, accumsan turpis libero
        aliquam risus. Posuere praesent sit metus nibh eget posuere sit.
      </div>
      <v-row align="center" justify="center">
        <v-col
          cols="12"
          style="position: fixed; left: 0; bottom: 100px; text-align: center"
        >
          <div class="div-bottom" style="color: #1a347f">
            <v-checkbox
              color="#1A347F"
              dense
              hide-details
              outlined
              class="mb-n6 ml-6"
            ></v-checkbox>
            <div class="ml-n16 fn14-weight7">Agree on terms & conditions</div>
          </div>
        </v-col>
        <v-col
          cols="12"
          style="position: fixed; left: 0; bottom: 10px; text-align: center"
        >
          <v-btn
            class="text-capitalize color-linear rounded-lg"
            style="display: block; margin-left: auto; margin-right: auto"
            dark
            width="350"
            height="57"
            @click="submit"
            >Register</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      id: "",
      brandName: "",
      brandDescription: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },
      checkValidate: true,
      lazy: false,
    };
  },
  created() {
    var Branddata = JSON.parse(
      Decode.decode(localStorage.getItem("Branddata"))
    );
    console.log("Branddata", Branddata);
    this.id = Branddata.id;
    this.brandName = Branddata.brandName;
    this.brandDescription = Branddata.description;
    this.activeFlag = Branddata.activeFlag;
    this.showImage = Branddata.brandImg;
    this.pic1 = Branddata.brandImg;
    this.picture_1 = Branddata.brandImg;
  },
  methods: {
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageBrand");
    },
    async submit() {
      this.$router.push("Verify");
      // if (this.$refs.createForm.validate(true)) {
      //   var user = JSON.parse(
      //     Decode.decode(localStorage.getItem("userBiData"))
      //   );
      //   const auth = {
      //     headers: {
      //       Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
      //     },
      //   };
      //   const data = {
      //     id: this.id,
      //     brandName: this.brandName,
      //     description: this.brandDescription,
      //     activeFlag: this.activeFlag,
      //     brandImg: this.pic1,
      //     sortIndex: 100,
      //   };
      //   const response = await this.axios.put(
      //     `${process.env.VUE_APP_API}/brands/` + this.id,
      //     data,
      //     auth
      //   );
      //   console.log("createBrand", response);
      //   console.log(response.data.response_status);
      //   if (response.data.response_status == "SUCCESS") {
      //     this.$swal.fire({
      //       icon: "success",
      //       text: `อัพเดตยี่ห้อสินค้าสำเร็จ`,
      //       showConfirmButton: false,
      //       timer: 1500,
      //     });
      //     this.$router.push("ManageBrand");
      //   } else {
      //     this.$swal.fire({
      //       icon: "error",
      //       text: `อัพเดตยี่ห้อสินค้าไม่สำเร็จ`,
      //       showConfirmButton: false,
      //       timer: 1500,
      //     });
      //   }
      // }
    },
  },
};
</script>

<style scoped>
.div-bottom {
  position: fixed;
  left: 0;
  bottom: 100px;
  width: 100%;
  text-align: center;
}
.btn-bottom {
  position: fixed;
  left: 0;
  bottom: 30px;
  text-align: center;
}
.color-linear {
  background: linear-gradient(268.1deg, #07204b 7.54%, #1a347f 100.72%);
}
</style>
